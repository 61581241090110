import React, { FC, SVGAttributes } from "react"

const instagramIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M8 0C5.827 0 5.556.01 4.701.05 1.795.182.181 1.794.048 4.7.009 5.556 0 5.828 0 8c0 2.173.01 2.445.048 3.299.133 2.905 1.747 4.52 4.653 4.653C5.556 15.991 5.827 16 8 16s2.445-.01 3.3-.048c2.901-.133 4.521-1.745 4.652-4.653.039-.854.048-1.126.048-3.299 0-2.172-.01-2.444-.048-3.299C15.822 1.8 14.207.181 11.3.05 10.445.01 10.173 0 8 0zm0 1.443c2.136 0 2.39.008 3.233.046 2.17.099 3.18 1.127 3.28 3.279.038.844.046 1.096.046 3.232s-.008 2.39-.046 3.232c-.1 2.15-1.109 3.181-3.28 3.28-.844.037-1.096.047-3.233.047-2.136 0-2.39-.008-3.232-.047-2.173-.1-3.18-1.132-3.28-3.28-.037-.843-.047-1.096-.047-3.232s.01-2.39.047-3.232c.099-2.153 1.11-3.181 3.28-3.28.843-.039 1.096-.045 3.232-.045zM3.892 8a4.108 4.108 0 118.216 0 4.108 4.108 0 01-8.216 0zM8 10.667a2.666 2.666 0 110-5.334 2.668 2.668 0 110 5.334zm3.31-6.936a.96.96 0 111.92 0 .96.96 0 01-1.92 0z"
      clipRule="evenodd"
    />
  </svg>

export default instagramIcon
