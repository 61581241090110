'use client'
import * as React from 'react'
import Dialog from '../Dialog'
import { useStore } from 'web/src/lib/context/store-context'

const QuantityError = () => {
    const { quantityError, setQuantityError } = useStore()

    return (
        <Dialog
            title={'Er ging iets mis, chef!'}
            content={<div>{quantityError}</div>}
            isOpen={!!quantityError}
            onClose={() => {
                setQuantityError(undefined)
            }}
        />
    )
}

export default QuantityError
