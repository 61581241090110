import React, { FC, SVGAttributes } from 'react'

const TruckIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M2 12H.667A.667.667 0 010 11.333V10h10V4h3.111c.745 0 1.067.384 1.291.738.396.627 1.024 1.621 1.406 2.252.125.208.192.447.192.69v2.987c0 .747-.485 1.333-1.333 1.333H14a2 2 0 01-4 0H6a2 2 0 01-4 0zm2-.8a.8.8 0 11-.001 1.601A.8.8 0 014 11.2zm8 0a.8.8 0 11-.001 1.601A.8.8 0 0112 11.2zM9.333 9.333H0V2.667C0 2.299.299 2 .667 2h8c.368 0 .666.299.666.667v6.666zm2-4v2h3.144l-.952-1.664a.666.666 0 00-.578-.336h-1.614z"
            clipRule="evenodd"
        ></path>
    </svg>
)

export default TruckIcon
