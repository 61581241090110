import * as React from 'react'
import { DialogProps } from './types'
import { Dialog } from '@headlessui/react'
import CloseIcon from '../../icons/closeIcon'
import Button from '../Button'
import ArrowNextIcon from '../../icons/arrowNextIcon'
import classNames from 'classnames'

const DialogComponent = ({
    title,
    image,
    content,
    isOpen = false,
    onClose,
    isCalender,
    buttonCopy,
    loading,
    onButtonClick,
    preventDefault = false,
}: DialogProps) => {
    const modal = 'fixed inset-0 z-[1100] overflow-y-scroll'
    const dialog = `flex ${
        isCalender ? 'max-w-[350px]' : 'max-w-[599px]'
    } min-h-[calc(100%-3rem)] items-center my-4 lg:my-6 mx-3 mx-auto`
    const panel = 'relative flex flex-col w-full bg-white rounded-[10px] overflow-hidden z-[9999]'
    const dialogStyles = {
        modal,
        panel,
        dialog,
    }
    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                preventDefault ? null : onClose
            }}
        >
            <div className="fixed inset-0 bg-black/50 z-[1100]" aria-hidden="true" />
            <div className={dialogStyles.modal}>
                <div className={dialogStyles.dialog}>
                    <Dialog.Panel className={dialogStyles.panel}>
                        <div className="h-20 flex items-center justify-between border-b-[1px] border-black/10 p-5">
                            <Dialog.Title as={'p'} className="font-bold text-xl">
                                {title}
                            </Dialog.Title>
                            {!preventDefault && (
                                <button
                                    className="btn btn-round w-10 h-10 bg-black flex lg:hover:cursor-pointer [&>svg]:flex-shrink-0"
                                    onClick={onClose}
                                    aria-label="Sluit pop-up"
                                >
                                    <CloseIcon stroke="#fff" />
                                </button>
                            )}
                        </div>
                        <div className="p-5">
                            {content}
                            {buttonCopy && (
                                <Button
                                    loading={!!loading}
                                    aria-label={buttonCopy}
                                    onClick={onButtonClick}
                                    rightIcon={<ArrowNextIcon fill={'white'} />}
                                    className={`btn-primary ${image ? 'absolute -top-[25px]' : ''} min-w-full mt-4`}
                                >
                                    {buttonCopy}
                                </Button>
                            )}
                        </div>
                        <div className="relative">{image && <div className={'[&>img]:h-52'}>{image}</div>}</div>
                    </Dialog.Panel>
                </div>
            </div>
        </Dialog>
    )
}

export default DialogComponent
