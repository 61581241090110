'use client'

import React from 'react'
import { FooterProps } from './types'
import SwiperText from '../Atoms/SwiperText'
import Link from 'next/link'
import Button from '../Button'
import icons from '../icons'
import KarmaIcon from '../../icons/karmaIcon'
import PaymentLogos from '../../icons/payment'

const Footer = ({ links, policies, socials }: FooterProps) => {
    const currentYear = new Date().getFullYear()

    return (
        <footer>
            <div className="relative overflow-hidden pt-52 -mt-52">
                <div className="relative pt-36 pb-28 lg:pt-[200px] bg-dark">
                    <div className="flex items-center justify-center">
                        <div className="absolute top-[-10px] lg:top-0 left-[-20px]">
                            <SwiperText color="green" rotate="rotate-[-6.52deg]" />
                        </div>
                        <div className="container">
                            <div className="flex-col md:flex-row flex-wrap flex justify-center text-white lg:mb-12 text-center">
                                <div className="m-auto md:m-0 mb-[48px] lg:mb-0 flex-shrink-0 w-[50%] lg:w-[218px] flex flex-col">
                                    <p className="font-Chaney tracking-wider uppercase text-base	leading-8 mb-1">
                                        Ga naar
                                    </p>
                                    {links &&
                                        links.map(link => {
                                            return link.label !== 'Contact' ? (
                                                <Link
                                                    aria-label={link.label}
                                                    className="text-white/80 lg:text-xs !leading-[48px] lg:!leading-[22px] font-medium"
                                                    href={link.slug}
                                                    key={link.slug}
                                                >
                                                    {link.label}
                                                </Link>
                                            ) : (
                                                <a
                                                    className="text-white/80 lg:text-xs !leading-[48px] lg:!leading-[22px] font-medium"
                                                    aria-label={link.label}
                                                    href={`${link.slug}`}
                                                    key={link.label}
                                                >
                                                    {link.label}
                                                </a>
                                            )
                                        })}
                                </div>
                                <div className="m-auto md:m-0 mb-[48px] lg:mb-0 leading-[22px] lg:mx-[120px] flex-shrink-0 w-[50%] lg:w-[218px] flex flex-col">
                                    <p className="font-Chaney tracking-wider uppercase text-base	leading-8 mb-1">
                                        Contact
                                    </p>
                                    <p className="text-white/80 lg:text-xs !leading-[48px] lg:!leading-[22px] font-medium">
                                        Alblasstraat 9
                                    </p>
                                    <p className="text-white/80 lg:text-xs !leading-[48px] lg:!leading-[22px] font-medium">
                                        3044 AB Rotterdam
                                    </p>
                                    <a
                                        className="text-white/80 lg:text-xs !leading-[48px] lg:!leading-[22px] font-medium"
                                        href="mailto:info@goodchef.nl"
                                    >
                                        info@goodchef.nl
                                    </a>
                                </div>
                                <div className="lg:w-[218px] flex flex-col items-center">
                                    <p className="font-Chaney tracking-wider uppercase text-base	leading-8 mb-2 text-white">
                                        Volg je ons al?
                                    </p>
                                    <div className="flex gap-2">
                                        {socials &&
                                            socials.map(social => (
                                                <Button
                                                    className="btn-round bg-green-brand [&>span>svg>path]:fill-dark w-12 h-12 [&>span>svg]:w-4 [&>span>svg]:h-4"
                                                    as="a"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={social.link}
                                                    key={social.link}
                                                    aria-label={`link naar ${social.icon}`}
                                                >
                                                    {social.icon && icons[social.icon]}
                                                </Button>
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute -bottom-4 animate-spinSlow">
                            <KarmaIcon fill="#B1FF00" width={80} height={80} />
                        </div>
                    </div>
                </div>
                <div className="pb-[120px] lg:pb-[80px] bg-white">
                    <div className="flex-col lg:flex-row container flex py-[20px] text-xs text-dark/70 leading-8 text-xs justify-between font-normal	">
                        <div className="justify-center flex-row flex-wrap flex lg:gap-4 items-center text-center">
                            <span className="w-full lg:w-fit flex-shrink-0 text-xs">
                                Copyright © {currentYear} Good Chef. Alle rechten voorbehouden.
                            </span>
                            <div className="flex gap-4 justify-center mb-5 lg:mb-0">
                                {policies &&
                                    policies.map(policy => {
                                        if (policy?.slug) {
                                            return (
                                                <Link aria-label={policy.label} href={policy.slug} key={policy.slug}>
                                                    {policy.label}
                                                </Link>
                                            )
                                        } else if (policy?.onClick) {
                                            return (
                                                <button
                                                    type="button"
                                                    aria-label={policy.label}
                                                    className="cursor-pointer"
                                                    onClick={policy.onClick}
                                                    key={policy.label}
                                                >
                                                    {policy.label}
                                                </button>
                                            )
                                        }
                                    })}
                            </div>
                        </div>
                        <div className="w-full lg:w-fit h-auto flex align-center justify-center lg:justify-end">
                            <PaymentLogos />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
