import React, { FC, SVGAttributes } from "react"

const AccountIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="current"
      fillRule="evenodd"
      d="M6.875 5.5a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.438 18.43a7.562 7.562 0 0115.124 0 .687.687 0 01-.401.637A17.127 17.127 0 0111 20.625c-2.554 0-4.98-.557-7.16-1.558a.687.687 0 01-.402-.637z"
      clipRule="evenodd"
    />
  </svg>

export default AccountIcon
