'use-client'

import LoadingIcon from '../../icons/loadingIcon'
import React from 'react'
import { CheckoutProgressProps } from './types'
import classNames from 'classnames'
import MailIcon from '../../icons/mailIcon'
import CheckoutStep from '../CheckoutStep'
import TruckIcon from '../../icons/truckIcon'
import RoundCheckmarkIcon from '../../icons/roundCheckmarkIcon'
import routes from 'web/src/lib/routes'
import { useRouter } from 'next/navigation'
import CloseIcon from '../../icons/closeIcon'

const CheckoutProgress = ({ step }: CheckoutProgressProps) => {
    const router = useRouter()
    return (
        <div className="hidden lg:flex w-fit">
            <CheckoutStep
                onClick={() => router.push(routes.email)}
                active
                icon={<MailIcon color={'#fff'} />}
                text={'E-mailadres'}
            />
            <div className="bg-black/40 w-[90px] h-1 mt-[15px] rounded-full">
                <div
                    className={classNames(`bg-green-a w-[0] absolute h-1 rounded-full`, {
                        'animate-fillBarWidth': step === 'gegevens',
                        'bg-green-a !w-[90px]': !['gegevens', 'email'].includes(step),
                    })}
                />
            </div>
            <CheckoutStep
                onClick={() => router.push(routes.details)}
                active={!['gegevens', 'email'].includes(step)}
                activated={step === 'gegevens'}
                icon={<MailIcon color={'#fff'} />}
                text={'Gegevens'}
            />
            <div className="bg-black/40 w-[90px] h-1 mt-[15px] rounded-full">
                <div
                    className={classNames(
                        `bg-green-a w-[0] absolute h-1 rounded-full`,
                        {
                            'bg-green-a !w-[90px]': ['betaling-mislukt', 'betaling', 'bedankt'].includes(
                                step.split('/')[0]
                            ),
                        },
                        { 'animate-fillBarWidth': step === 'bezorging' }
                    )}
                />
            </div>
            <CheckoutStep
                onClick={() => router.push(routes.delivery)}
                activated={step === 'bezorging'}
                active={['betaling-mislukt', 'betaling', 'bedankt'].includes(step.split('/')[0])}
                icon={<TruckIcon color={'#fff'} />}
                text={'Bezorging'}
            />
            <div className="bg-black/40 w-[90px] h-1 mt-[15px] rounded-full">
                <div
                    className={classNames(
                        `bg-green-a w-[0] absolute h-1 rounded-full`,
                        { 'animate-fillBarWidth': ['betaling', 'bedankt'].includes(step.split('/')[0]) },
                        { 'bg-red-a w-[0] animate-fillBarWidth': ['betaling-mislukt'].includes(step) }
                    )}
                />
            </div>
            <CheckoutStep
                declined={step === 'betaling-mislukt'}
                activated={step.split('/')[0] === 'bedankt'}
                icon={
                    step === 'betaling-controleren' ? (
                        <LoadingIcon width={25} height={25} color={'#fff'} />
                    ) : step === 'betaling-mislukt' ? (
                        <CloseIcon stroke="#fff" />
                    ) : (
                        <RoundCheckmarkIcon color={'#fff'} />
                    )
                }
                text={'Betaling'}
            />
        </div>
    )
}

export default CheckoutProgress
