import React, { FC, SVGAttributes } from 'react'

const RoundCheckmarkIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16" {...props}>
        <g clipPath="url(#clip0_1617_977)">
            <path
                fill="currentColor"
                d="M8 0a8 8 0 100 16A8 8 0 008 0zm-.833 11.528l-3-2.91L5.405 7.38l1.762 1.67 3.762-3.855 1.238 1.238-5 5.095z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_1617_977">
                <path fill="currentColor" d="M0 0H16V16H0z"></path>
            </clipPath>
        </defs>
    </svg>
)

export default RoundCheckmarkIcon
