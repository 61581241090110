import React, { FC, SVGAttributes } from "react"

const linkedinIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="current"
      d="M12.667 0H3.333A3.334 3.334 0 000 3.333v9.334A3.334 3.334 0 003.333 16h9.334A3.333 3.333 0 0016 12.667V3.333A3.333 3.333 0 0012.667 0zM5.333 12.667h-2V5.333h2v7.334zm-1-8.179a1.172 1.172 0 01-1.166-1.176c0-.65.522-1.176 1.166-1.176.644 0 1.167.527 1.167 1.176 0 .65-.522 1.176-1.167 1.176zm9 8.179h-2V8.93c0-2.246-2.666-2.076-2.666 0v3.736h-2V5.333h2V6.51c.93-1.724 4.666-1.851 4.666 1.65v4.507z"
    />
  </svg>

export default linkedinIcon
