'use client'

import React from 'react'
import type { SwiperTextProps } from './types'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'

const SwiperText = ({ text, color, rotate }: SwiperTextProps) => {
    const slideText = `${text ? text.toString().toUpperCase() : 'PLANTAARDIG LEKKER'}`

    return (
        <div
            className={classNames(
                `select-none h-[56px] lg:h-[70px] flex items-center justify-center w-[calc(100vw+40vw)] -ml-2 relative z-10 overflow-hidden whitespace-nowrap ${rotate} `,
                { 'bg-orange-c': color === 'red' },
                { 'text-white bg-green-a': color === 'green' },
            )}
        >
            <Swiper
                modules={[Autoplay]}
                className={'flex items-center justify-center [&>div]:ease-linear'}
                slidesPerView="auto"
                speed={8000}
                loop
                noSwiping
                allowTouchMove={false}
                keyboard={false}
                autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                }}
            >
                {Array.from(Array(20).keys()).map(index => (
                    <SwiperSlide key={index} className={'w-fit'}>
                        <span className="font-Chaney text-base lg:text-xl font-normal">
                            {slideText} &nbsp; • &nbsp;&nbsp;
                        </span>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default SwiperText
