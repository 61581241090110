import LinkedinIcon from '../icons/linkedinIcon'
import InstagramIcon from '../icons/instagramIcon'
import FacebookIcon from '../icons/facebookIcon'

const icons: any = {
    instagram: InstagramIcon({}),
    linkedin: LinkedinIcon({}),
    facebook: FacebookIcon({}),
}

export default icons
