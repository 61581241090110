import React, { useEffect } from 'react'
import { trackContactSupport } from '../../utils/tracking/trackContactSupport'

const useContactSupportTracking = () => {
    const handleClick = (e: MouseEvent) => {
        const element = e.target as HTMLElement
        if (element.tagName === 'A') {
            const link = element as HTMLAnchorElement
            if (link.href.includes('@goodchef.nl')) {
                trackContactSupport('email')
            } else if (link.href.includes('tel:')) {
                trackContactSupport('phone')
            }
        }
    }

    useEffect(() => {
        window.addEventListener('click', handleClick)

        return () => window.removeEventListener('click', handleClick)
    })

    return null
}

export default useContactSupportTracking
