'use client'

import * as React from 'react'
import { CarouselProps } from './types'
import classNames from 'classnames'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getImageFromBucket } from 'web/src/lib/util/getImageFromBucket'
import Image from 'next/image'
import { klaviyoFormEnabled } from 'web/src/utils/tracking'
import Button from '../Button'
const images = [
    'Burrito_Curry_Up.jpg',
    'Burrito_Tex_Mex.jpg',
    'Coco_Mango.jpg',
    'Erwtensoep.jpg',
    'Farro_Boerenkool.jpg',
    'Gnochetti_Al_Ragu.jpg',
    'Green_Protein_Bowl.jpg',
    'Lemon_Twist_Tajine.jpg',
    'Mac_No_Cheese.jpg',
    'Mushroom_Risotto.jpg',
    'No_boeuf_bourguignon.jpg',
    'Oh-La-Lasagna.jpg',
    'Pasta_Famiglia.jpg',
    'Persian_Rice_Salad.jpg',
    'Red_Coconut_Lentil_soup.jpg',
    'Spaghetti_Bolo.jpg',
    'Sunshine_Curry.jpg',
    'Sweet_Chilli.jpg',
    'Sweet_Potato_bowl.jpg',
    'Tikka_Massala.jpg',
]
const Carousel = ({ is404 }: CarouselProps) => {
    const getAllSlides = (images: string[]) =>
        images.map(image => {
            const src = getImageFromBucket(image)
            return <Image sizes="(max-width: 768px) 50vw, 25vw" src={src} alt="" width="200" height="200" />
        })

    const openKlaviyoForm = () => {
        if (klaviyoFormEnabled()) {
            window._klOnsite = window._klOnsite || []
            window._klOnsite.push(['openForm', 'QUGDTx'])
        }
    }

    let defaultSlides = getAllSlides(images)

    const swiperSlides = defaultSlides

    return (
        <div className="mt-16 lg:mt-24 relative overflow-hidden pb-[130px] -mb-[400px] z-10">
            {!is404 && (
                <div
                    className={classNames(
                        `px-3 lg:px-8 container flex flex-col [&>h2]:mb-2.5 lg:[&>h2]:mb-3 [&>span]:text-base text-center max-w-screen-md`
                    )}
                >
                    <h2>Blijf op de hoogte</h2>
                    <p>
                        Friends with benefits? Daar doen we het voor. Wil jij nieuwe plantaardige gerechten, kooktips
                        van onze wereld-koks en de lekkerste acties in je mailbox? Schrijf je in.
                    </p>
                    <Button
                        aria-label="Schrijf je in voor de nieuwsbrief"
                        onClick={() => {
                            openKlaviyoForm()
                        }}
                        className={classNames(`w-[200px] m-auto btn-primary mt-6`)}
                    >
                        Schrijf je in
                    </Button>
                </div>
            )}
            <div className="w-[calc(100vw+40px)] -translate-x-5 h-auto rotate-2 mt-16 lg:mt-24 z-20">
                <Swiper
                    className="[&>div]:ease-linear"
                    noSwiping
                    allowTouchMove={false}
                    keyboard={false}
                    modules={[Autoplay]}
                    speed={8000}
                    loop
                    autoplay={{
                        delay: 1,
                        disableOnInteraction: false,
                        reverseDirection: true,
                    }}
                    slidesPerView="auto"
                >
                    {swiperSlides?.map((slide, index) => (
                        <SwiperSlide
                            className="max-w-[200px] lg:max-w-[371px] mr-2 lg:mr-5 rounded overflow-hidden"
                            key={`footer_${index}`}
                        >
                            {slide}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Carousel
