import React, { FC, SVGAttributes } from 'react'

const GoodChef: FC<SVGAttributes<SVGElement>> = ({ ...props }) => (
    <svg width="153" height="19" viewBox="0 0 153 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.269531 9.9353C0.269531 4.56348 4.24703 0.93457 9.81699 0.93457H9.89037C14.565 0.93457 18.1206 3.47004 19.1406 7.47322H12.9726C12.3745 6.67698 11.3067 6.03119 9.89037 6.03119H9.81699C7.40627 6.03119 5.83949 7.67135 5.83949 9.983C5.83949 12.2946 7.40627 14.0596 9.81699 14.0596H9.89037C11.3581 14.0596 12.4772 13.4138 12.8258 12.8157H9.89037V8.83818L19.3644 8.86387V9.93163C19.3644 14.9292 15.4603 18.9324 9.89037 18.9324H9.81699C4.37178 18.9324 0.269531 15.3255 0.269531 9.93163V9.9353Z" />
        <path d="M40.0257 9.9353C40.0257 15.3328 36.0482 18.936 30.4783 18.936H30.4049C24.9597 18.936 20.8574 15.3291 20.8574 9.9353C20.8574 4.54147 24.8349 0.93457 30.4049 0.93457H30.4783C36.0482 0.93457 40.0257 4.56348 40.0257 9.9353ZM34.4557 9.9353C34.4557 7.67136 32.8413 6.03119 30.4783 6.03119H30.4049C28.0419 6.03119 26.4274 7.67136 26.4274 9.9353C26.4274 12.1992 28.0455 13.8394 30.4049 13.8394H30.4783C32.8413 13.8394 34.4557 12.2213 34.4557 9.9353Z" />
        <path d="M60.6878 9.9353C60.6878 15.3328 56.7103 18.936 51.1404 18.936H51.067C45.6218 18.936 41.5195 15.3291 41.5195 9.9353C41.5195 4.54147 45.497 0.93457 51.067 0.93457H51.1404C56.7103 0.93457 60.6878 4.56348 60.6878 9.9353ZM55.1179 9.9353C55.1179 7.67136 53.5034 6.03119 51.1404 6.03119H51.067C48.704 6.03119 47.0895 7.67136 47.0895 9.9353C47.0895 12.1992 48.7076 13.8394 51.067 13.8394H51.1404C53.5034 13.8394 55.1179 12.2213 55.1179 9.9353Z" />
        <path d="M79.9077 9.93545C79.9077 15.3073 75.7797 18.639 70.3345 18.639H62.6768V1.23193H70.3345C75.7797 1.23193 79.9077 4.53795 79.9077 9.93545ZM74.3377 9.93545C74.3377 7.69719 72.6462 6.00566 70.3345 6.00566H68.122V13.8616H70.3345C72.6462 13.8102 74.3377 12.1444 74.3377 9.93178V9.93545Z" />
        <path d="M94.8233 11.1535H100.492C99.9456 15.8538 96.1663 18.936 91.0183 18.936H90.9449C85.4997 18.936 81.3975 15.3291 81.3975 9.9353C81.3975 4.54147 85.375 0.93457 90.9449 0.93457H91.0183C96.1663 0.93457 99.9456 4.01676 100.492 8.7171H94.8233C94.3243 7.20169 92.908 6.03119 91.0183 6.03119H90.9449C88.5819 6.03119 86.9674 7.67136 86.9674 9.9353C86.9674 12.1992 88.5856 13.8394 90.9449 13.8394H91.0183C92.908 13.8394 94.3243 12.6946 94.8233 11.1535Z" />
        <path d="M119.686 1.23193V18.6353H114.216V12.17H107.948V18.6353H102.478V1.23193H107.948V7.32293H114.216V1.23193H119.686Z" />
        <path d="M127.641 5.95796V7.6972H134.653V12.2214H127.641V13.8873H136.345V18.6353H122.174V1.23193H136.345V5.95796H127.641Z" />
        <path d="M144.3 5.95796V8.34299H151.312V13.069H144.3V18.639H138.829V1.23193H153V5.95796H144.296H144.3Z" />
    </svg>
)

export default GoodChef
