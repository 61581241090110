'use client'
import React, { useEffect, useRef, useState } from 'react'
import type { HeaderProps } from './types'
import AccountIcon from '../../icons/accountIcon'
import Button from '../Button'
import classNames from 'classnames'
import useSticky from '../../hooks/useSticky'
import BackIcon from '../../icons/backIcon'
import CheckoutProgress from '../CheckoutProgress'
import routes from 'web/src/lib/routes'
import { useRouter } from 'next/navigation'
import { useAccount } from 'web/src/lib/context/account-context'
import ChefLogo from '../../icons/chefLogo'
import GoodChef from '../../icons/goodChef'
import Link from 'next/link'
import useDetectScroll from '../../hooks/useDetectScroll'
import { useStore } from 'web/src/lib/context/store-context'
import CloseIcon from '../../icons/closeIcon'
const Header = ({
    dark,
    darkOnMobile,
    disableAccountIcon = false,
    backButton = false,
    color,
    checkoutProgress,
    useGradient,
    overflow,
    accountHeader,
    formHeader,
}: HeaderProps) => {
    const { customer } = useAccount()
    const ref = useRef<HTMLDivElement>(null)
    const isSticky = useSticky()
    const router = useRouter()
    const [scrollDir] = useDetectScroll({})
    const { menuOpen, setMenuOpen, hideHeader, setHideHeader } = useStore()

    useEffect(() => {
        if (window.scrollY > 0) {
            if (scrollDir === 'down') {
                setHideHeader(true)
            } else {
                setHideHeader(false)
            }
        } else {
            setHideHeader(false)
        }
    }, [scrollDir])

    return (
        <header
            ref={ref}
            className={classNames(
                `z-[1060] lg:z-[1095] bg-transparent flex top-0 fixed transform-gpu menuTransition h-[105px] w-full`,
                { [`${color}`]: color && isSticky },
                { 'lg:h-[120px] h-[90px] !bg-transparent': !isSticky && !accountHeader },
                { 'bg-white': !color && isSticky },
                { 'custom-gradient !bg-transparent': useGradient && !isSticky },
                { 'bg-none h-[70px] lg:h-[90px] border-b-[1px] border-grey/20': isSticky },
                { '-translate-y-full': hideHeader },
                { '!bg-transparent': formHeader },
                {
                    'lg:!bg-white !h-[90px] items-center border-b-[1px] border-black/5':
                        checkoutProgress || accountHeader,
                },
                {
                    'lg:border-b-[1px] !bg-[#FFFAEC]': accountHeader,
                },
                { '!border-0': accountHeader && !isSticky }
            )}
        >
            <div
                className={classNames(`container flex flex-col`, {
                    'justify-center': isSticky || !overflow,
                })}
            >
                <div className="flex items-center justify-between">
                    <Link
                        href="/"
                        aria-label="Ga naar de homepage"
                        className="group flex items-center gap-2 lg:hover:cursor-pointer"
                    >
                        <div className="lg:group-hover:scale-x-[-1]">
                            <ChefLogo />
                        </div>
                        <div
                            className={classNames(
                                { '[&>svg_path]:fill-dark': dark && !isSticky },
                                { '[&>svg_path]:fill-dark': isSticky },
                                { '[&>svg_path]:fill-white': !dark && !isSticky && !darkOnMobile },
                                { '[&>svg_path]:fill-dark': darkOnMobile && dark },
                                { '[&>svg_path]:fill-dark lg:[&>svg_path]:fill-white': darkOnMobile && !dark }
                            )}
                        >
                            <GoodChef />
                        </div>
                    </Link>
                    {checkoutProgress && <CheckoutProgress step={checkoutProgress} />}
                    <div className="flex gap-[12px] items-center">
                        {!disableAccountIcon && (
                            <Link
                                aria-label={!customer ? 'Ga naar de login pagina' : 'Ga naar account omgeving'}
                                href={!customer ? routes.login : routes.account}
                                passHref
                            >
                                <Button
                                    aria-label={'Ga naar account omgeving'}
                                    className={'bg-green-light btn-round w-10 h-10'}
                                >
                                    <AccountIcon fill="#242424" />
                                </Button>
                            </Link>
                        )}
                        {!backButton && !customer && (
                            <Link aria-label="Kies mijn box" href={routes.products} passHref>
                                <Button
                                    aria-label={'Kies mijn box'}
                                    className={classNames(
                                        `hidden lg:flex uppercase btn-primary w-fit w-40 h-10 [&>span]:text-sm [&>span]:leading-6`,
                                        { 'bg-dark': isSticky },
                                        { 'bg-white text-dark': !isSticky }
                                    )}
                                >
                                    Kies mijn box
                                </Button>
                            </Link>
                        )}
                        {!checkoutProgress && (
                            <Button
                                aria-label="Toggle het menu"
                                onClick={() => setMenuOpen(!menuOpen)}
                                noChildren
                                leftIcon={
                                    menuOpen ? (
                                        <CloseIcon stroke="#000" />
                                    ) : (
                                        <div className="transition-all duration-200 ease gap-[5px] flex flex-col items-center justify-center">
                                            <span
                                                className={classNames(
                                                    `rounded-[1px] block bg-black w-[14px] h-[2px]`,
                                                    { 'bg-white': accountHeader || isSticky },
                                                    { 'bg-black': !accountHeader }
                                                )}
                                            />
                                            <span
                                                className={classNames(
                                                    `rounded-[1px] block bg-black w-[18px] h-[2px]`,
                                                    { 'bg-white': accountHeader || isSticky },
                                                    { 'bg-black': !accountHeader }
                                                )}
                                            />
                                            <span
                                                className={classNames(
                                                    `rounded-[1px] block bg-black w-[14px] h-[2px]`,
                                                    { 'bg-white': accountHeader || isSticky },
                                                    { 'bg-black': !accountHeader }
                                                )}
                                            />
                                        </div>
                                    )
                                }
                                className={classNames(
                                    `btn`,
                                    { 'btn-menu-dark': accountHeader || isSticky },
                                    { 'btn-menu': !accountHeader }
                                )}
                            />
                        )}
                        {backButton && (
                            <Button
                                aria-label={'Ga een stap terug'}
                                onClick={() => router.back()}
                                className="bg-dark btn-round w-[40px] h-[40px]"
                            >
                                <BackIcon stroke="#fff" fill="#fff" />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
