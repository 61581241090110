export const constants = {
    socials: [
        {
            link: 'https://www.linkedin.com/company/goodchefnl/',
            icon: 'linkedin',
            shareUrl: (location: string) => `https://www.linkedin.com/sharing/share-offsite/?url=${location}`,
        },
        {
            link: 'https://www.instagram.com/goodchef.nl/',
            icon: 'instagram',
            shareUrl: (location: string) => `https://www.instagram.com/?url=${location}`,
        },
        {
            link: 'https://www.facebook.com/goodchef.nl/',
            icon: 'facebook',
            shareUrl: (location: string) => `https://www.facebook.com/sharer/sharer.php?u=${location}`,
        },
    ],
}
