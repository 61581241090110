import React, { FC, SVGAttributes } from "react"

const backIcon: FC<SVGAttributes<SVGElement>> = ({ ...props }) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="current"
      stroke="current"
      strokeWidth="0.5"
      d="M5.862 10.875H16.25a.875.875 0 100-1.75H5.862L9.99 4.996A.874.874 0 108.754 3.76L3.132 9.382h0a.875.875 0 000 1.236h0l5.622 5.623a.874.874 0 101.237-1.237l-4.13-4.129z"
    />
  </svg>

export default backIcon
