import React, { useEffect } from 'react'
import { NavigationProps } from './types'
import Link from 'next/link'
import Button from '../Button'
import CloseIcon from '../../icons/closeIcon'
import classNames from 'classnames'
import KarmaIcon from '../../icons/karmaIcon'
import { scrollLock } from '../../utils/scrollLock'
import { useAccount } from 'web/src/lib/context/account-context'
import Overlay from '../Atoms/Overlay'
import { handleEscKeyPress } from '../../utils/handleEscKeyPress'

const Navigation = ({ menuItems, account, contact, navigationOpen, onClose }: NavigationProps) => {
    const { customer, handleLogout } = useAccount()
    const eventOptions: AddEventListenerOptions = { passive: true }
    useEffect(() => {
        if (navigationOpen) {
            scrollLock.enable()
            document.addEventListener('keydown', e => handleEscKeyPress(e, () => onClose()))
        } else {
            scrollLock.disable()
            document.removeEventListener('keydown', e => handleEscKeyPress(e, () => onClose()))
        }
        return () => {
            scrollLock.disable()
            document.removeEventListener('keydown', e => handleEscKeyPress(e, () => onClose()))
        }
    }, [navigationOpen])

    return (
        <>
            <div
                className={classNames(
                    'hidden lg:block fixed translate-all transform-gpu ease duration-500 w-screen h-screen top-0 left-0 z-[1100]',
                    { 'opacity-0 pointer-events-none': !navigationOpen },
                    { 'opacity-100': navigationOpen }
                )}
            >
                <Overlay onClick={() => onClose()} />
            </div>
            <div
                className={classNames(
                    'bg-white lg:max-w-[600px] w-screen h-screen transition-all duration-500 overflow-hidden bg-dark w-full z-[1100] fixed top-0 bottom-0 right-0',
                    { 'opacity-0-100 translate-y-0 lg:translate-x-0 ': navigationOpen },
                    { 'opacity-0 translate-y-full lg:translate-y-0 lg:translate-x-full': !navigationOpen }
                )}
            >
                <div className="z-10 pr-4 fixed right-0 top-0 flex justify-end items-center h-[90px]">
                    <Button aria-label={'Sluiten'} className="btn-round bg-dark h-10 w-10" onClick={onClose}>
                        <CloseIcon stroke="#fff" />
                    </Button>
                </div>
                <div className="overflow-auto pt-[90px] h-[calc(100%-80px)] lg:h-[calc(100%-176px)] bg-white pl-7 pr-4 relative">
                    <div className="container">
                        <div className="flex flex-col ">
                            <div className="mb-10 flex flex-col font-bold text-3xl leading-[48px]">
                                {menuItems &&
                                    menuItems.map(menuItem => (
                                        <Link
                                            aria-label={menuItem.label}
                                            href={`${menuItem.slug}`}
                                            key={menuItem.label}
                                        >
                                            {menuItem.label}
                                        </Link>
                                    ))}
                            </div>
                            <div className="text-base flex flex-col leading-9 border-b border-black/20 pb-5 text-dark">
                                <Link aria-label={account.label} href={`${account.slug}`} key={account.label}>
                                    {account.label}
                                </Link>
                                <a
                                    href={'/'}
                                    className={classNames('cursor-pointer', { hidden: !customer })}
                                    aria-label={'uitloggen'}
                                    onClick={e => {
                                        e.preventDefault()
                                        handleLogout()
                                    }}
                                >
                                    Uitloggen
                                </a>
                                <a aria-label={contact.label} href={`${contact.slug}`} key={contact.label}>
                                    {contact.label}
                                </a>
                            </div>
                            {/*<div className="mt-5 flex items-center">*/}
                            {/*    <Button className="bg-white btn-round h-10 w-10 shadow mr-5 pointer-events-none">*/}
                            {/*        <NlIcon />*/}
                            {/*    </Button>*/}
                            {/*    <Popover className="relative">*/}
                            {/*        {({ open }) => (*/}
                            {/*            <>*/}
                            {/*                <Popover.Button className="font-bold flex items-center [&>svg]:ml-2">*/}
                            {/*                    {currentLang}*/}
                            {/*                    <ChevronDownIcon*/}
                            {/*                        fill="#242424"*/}
                            {/*                        stroke="#242424"*/}
                            {/*                        className={`${open ? 'rotate-180' : ''}`}*/}
                            {/*                    />*/}
                            {/*                </Popover.Button>*/}
                            {/*                <Popover.Panel className="absolute left-0 z-10 mt-3 w-screen max-w-sm transform ">*/}
                            {/*                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">*/}
                            {/*                        <div className="relative flex flex-col gap-8 bg-white p-7">*/}
                            {/*                            {languages.map(lang => (*/}
                            {/*                                <a*/}
                            {/*                                    key={lang.short}*/}
                            {/*                                    onClick={() => setCurrentLang(lang.long)}*/}
                            {/*                                    className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"*/}
                            {/*                                >*/}
                            {/*                                    <p className="text-sm font-medium text-gray-900">*/}
                            {/*                                        {lang.long}*/}
                            {/*                                    </p>*/}
                            {/*                                </a>*/}
                            {/*                            ))}*/}
                            {/*                        </div>*/}
                            {/*                    </div>*/}
                            {/*                </Popover.Panel>*/}
                            {/*            </>*/}
                            {/*        )}*/}
                            {/*    </Popover>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
                <div className="relative bg-black h-20 lg:h-44">
                    <div className="absolute -top-20 right-14">
                        <KarmaIcon fill="#B1FF00" width={120} height={120} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navigation
