import { useState, useEffect } from 'react'

type DetectScrollProps = {
    thr?: number
    axis?: string
    scrollUp?: string
    scrollDown?: string
    still?: string
}

function useDetectScroll({
    thr = 0,
    axis = 'y',
    scrollUp = axis === 'y' ? 'up' : 'left',
    scrollDown = axis === 'y' ? 'down' : 'right',
    still = 'still',
}: DetectScrollProps) {
    const [scrollDir, setScrollDir] = useState(still)

    useEffect(() => {
        const threshold = thr > 0 ? thr : 0
        let ticking = false
        let lastScroll: number

        axis === 'y' ? (lastScroll = window.pageYOffset) : (lastScroll = window.pageXOffset)

        const updateScrollDir = () => {
            let scroll = undefined

            axis === 'y' ? (scroll = window.pageYOffset) : (scroll = window.pageXOffset)

            if (Math.abs(scroll - lastScroll) < threshold) {
                ticking = false
                return
            }
            setScrollDir(scroll > lastScroll ? scrollDown : scrollUp)
            lastScroll = scroll > 0 ? scrollY : 0
            ticking = false
        }

        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir)
                ticking = true
            }
        }

        window.addEventListener('scroll', onScroll, { passive: true })

        return () => window.removeEventListener('scroll', onScroll)
    }, [scrollDir])

    return [scrollDir]
}

export default useDetectScroll
